import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx } from "vue";
import _imports_0 from '../../../public/SHDRCastle&Mickey.png';
import _imports_1 from '../../../public/iconphone.png';
import _imports_2 from '../../../public/iconmail.png';
import _imports_3 from '../../../public/iconcheckdetail.png';
import _imports_4 from '../../../public/btmpic.png';
const _hoisted_1 = {
  style: {
    "width": "100%",
    "height": "188px",
    "background": "linear-gradient(135deg, #4284FF 0%, #4284FF 49.33%, #B272FF 100%)",
    "position": "relative",
    "display": "inline-block"
  }
};
const _hoisted_2 = {
  style: {
    "position": "absolute",
    "top": "44px",
    "left": "20px"
  },
  class: "bannertitlest systemfont"
};
const _hoisted_3 = {
  style: {
    "position": "absolute",
    "top": "78px",
    "left": "20px"
  },
  class: "bannertitlesf systemfont"
};
const _hoisted_4 = {
  style: {
    "padding": "20px 14px 12px 14px"
  }
};
const _hoisted_5 = {
  style: {
    "padding": "12px 14px"
  }
};
const _hoisted_6 = {
  key: 0
};
const _hoisted_7 = {
  key: 1
};
const _hoisted_8 = {
  key: 2
};
const _hoisted_9 = {
  key: 3
};
const _hoisted_10 = {
  style: {
    "padding": "12px 14px"
  }
};
const _hoisted_11 = {
  style: {
    "width": "100%",
    "left": "0",
    "right": "0"
  }
};
const _hoisted_12 = {
  style: {
    "padding": "24px 14px",
    "height": "75px"
  }
};
const _hoisted_13 = {
  style: {
    "padding": "7px 0 0 0"
  }
};
const _hoisted_14 = {
  class: "btmtext systemfont"
};
const _hoisted_15 = {
  key: 0
};
const _hoisted_16 = {
  key: 1
};
const _hoisted_17 = {
  style: {
    "padding": "7px 0 0 0"
  }
};
const _hoisted_18 = {
  class: "btmtext systemfont"
};
const _hoisted_19 = {
  key: 0
};
const _hoisted_20 = {
  key: 1
};
const _hoisted_21 = {
  style: {
    "padding": "7px 0 0 0"
  }
};
const _hoisted_22 = {
  class: "btmtext systemfont"
};
const _hoisted_23 = {
  key: 0
};
const _hoisted_24 = {
  key: 1
};
const _hoisted_25 = {
  style: {
    "display": "flex",
    "justify-content": "center",
    "align-items": "center",
    "padding": "0 14px"
  }
};
const _hoisted_26 = ["src"];
const _hoisted_27 = {
  key: 1,
  src: _imports_4,
  style: {
    "width": "100%"
  }
};
const _hoisted_28 = {
  key: 0,
  style: {
    "margin": "24px 14px 0 14px",
    "display": "flex",
    "flex-direction": "row",
    "justify-content": "center",
    "align-items": "center"
  }
};
const _hoisted_29 = {
  class: "nocode systemfont wordbreak"
};
const _hoisted_30 = {
  class: "cliclhere systemfont wordbreak",
  href: 'tel:400-180-0000'
};
const _hoisted_31 = {
  key: 1,
  style: {
    "margin": "24px 14px 0 14px",
    "display": "flex",
    "flex-direction": "row",
    "justify-content": "center",
    "align-items": "center"
  }
};
const _hoisted_32 = {
  class: "nocode systemfont wordbreak"
};
const _hoisted_33 = {
  class: "cliclhere systemfont wordbreak",
  href: 'tel:400-180-0000'
};
const _hoisted_34 = {
  style: {
    "z-index": "999"
  }
};
const _hoisted_35 = {
  style: {
    "float": "left"
  }
};
const _hoisted_36 = {
  style: {
    "float": "right"
  }
};
const _hoisted_37 = {
  class: "checkicon"
};
const _hoisted_38 = {
  key: 0,
  style: {
    "float": "left"
  }
};
const _hoisted_39 = {
  key: 1,
  style: {
    "float": "left"
  }
};
const _hoisted_40 = {
  style: {
    "float": "right"
  }
};
const _hoisted_41 = {
  class: "sentitle systemfont"
};
const _hoisted_42 = {
  class: "sencont systemfont"
};
const _hoisted_43 = {
  style: {
    "position": "absolute",
    "padding": "8px 12px",
    "word-break": "break-all",
    "word-wrap": "break-word",
    "height": "18px"
  }
};
const _hoisted_44 = {
  class: "errmsg systemfont"
};
const _hoisted_45 = {
  style: {
    "position": "absolute",
    "padding": "8px 12px",
    "word-break": "break-all",
    "word-wrap": "break-word",
    "height": "18px"
  }
};
const _hoisted_46 = {
  class: "errmsg systemfont"
};
const _hoisted_47 = {
  class: "sentitle systemfont"
};
const _hoisted_48 = {
  class: "sencont systemfont"
};
const _hoisted_49 = {
  style: {
    "position": "absolute",
    "padding": "8px 12px",
    "word-break": "break-all",
    "word-wrap": "break-word",
    "height": "18px"
  }
};
const _hoisted_50 = {
  class: "errmsg systemfont"
};
const _hoisted_51 = {
  style: {
    "position": "absolute",
    "padding": "8px 12px",
    "word-break": "break-all",
    "word-wrap": "break-word",
    "height": "18px"
  }
};
const _hoisted_52 = {
  class: "errmsg systemfont"
};
const _hoisted_53 = {
  class: "errtitle systemfont"
};
const _hoisted_54 = {
  class: "errcont systemfont"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");
  const _component_van_field = _resolveComponent("van-field");
  const _component_van_popup = _resolveComponent("van-popup");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('IsSucPage.Efsuc')), 1), _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('IsSucPage.hassent')), 1), _cache[16] || (_cache[16] = _createElementVNode("div", {
    style: {
      "position": "absolute",
      "top": "30px",
      "right": "22px"
    }
  }, [_createElementVNode("img", {
    src: _imports_0,
    style: {
      "max-width": "110px",
      "max-height": "137px"
    }
  })], -1))]), _createElementVNode("div", {
    class: "contentcss",
    style: _normalizeStyle({
      height: '466px',
      width: $data.tempwidth
    })
  }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
    class: _normalizeClass($data.titlecss)
  }, _toDisplayString(_ctx.$t('IsSucPage.amount')), 3), _createElementVNode("div", {
    class: _normalizeClass($data.contcss)
  }, "¥ " + _toDisplayString($data.InvoiceAmount), 3)]), _createElementVNode("hr", {
    style: _normalizeStyle([{
      "border": "0.5px solid #EDEDF0",
      "margin": "0 14px"
    }, {
      width: $data.hrwidth
    }])
  }, null, 4), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", {
    class: _normalizeClass($data.titlecss)
  }, _toDisplayString(_ctx.$t('IsSucPage.type')), 3), _createElementVNode("div", {
    class: _normalizeClass($data.contcss)
  }, [$data.InvoiceType == 'EC' ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('AppPage.Valueaddec')), 1)) : $data.InvoiceType == 'BEC' ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('AppPage.Valueaddbec')), 1)) : $data.InvoiceType == 'BES' ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('AppPage.Valueaddbes')), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_9, " "))], 2)]), _createElementVNode("hr", {
    style: _normalizeStyle([{
      "border": "0.5px solid #EDEDF0",
      "margin": "0 14px"
    }, {
      width: $data.hrwidth
    }])
  }, null, 4), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", {
    class: _normalizeClass($data.titlecss)
  }, _toDisplayString(_ctx.$t('IsSucPage.time')), 3), _createElementVNode("div", {
    class: _normalizeClass($data.contcss)
  }, _toDisplayString($data.InvoiceDatetime), 3)]), _createElementVNode("hr", {
    style: _normalizeStyle([{
      "border": "0.5px solid #EDEDF0",
      "margin": "0 14px"
    }, {
      width: $data.hrwidth
    }])
  }, null, 4), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createElementVNode("div", {
    class: _normalizeClass($data.circlebtn),
    onClick: _cache[0] || (_cache[0] = (...args) => $options.onOpenMobile && $options.onOpenMobile(...args))
  }, [_createElementVNode("div", null, [_cache[18] || (_cache[18] = _createElementVNode("div", {
    class: "picture centerall"
  }, [_createElementVNode("img", {
    src: _imports_1,
    class: "picture"
  })], -1)), _createElementVNode("div", _hoisted_13, [_createElementVNode("span", _hoisted_14, [$data.titlelan == 'zh' ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.$t('IsSucPage.sendphone')), 1)) : _createCommentVNode("", true), $data.titlelan == 'en' ? (_openBlock(), _createElementBlock("div", _hoisted_16, [_createTextVNode(_toDisplayString(_ctx.$t('IsSucPage.sendphonef')), 1), _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)), _createTextVNode(_toDisplayString(_ctx.$t('IsSucPage.sendphones')), 1)])) : _createCommentVNode("", true)])])])], 2), _createElementVNode("div", {
    class: _normalizeClass($data.circlebtn),
    onClick: _cache[1] || (_cache[1] = (...args) => $options.onOpenEmail && $options.onOpenEmail(...args))
  }, [_createElementVNode("div", null, [_cache[20] || (_cache[20] = _createElementVNode("div", {
    class: "picture centerall"
  }, [_createElementVNode("img", {
    src: _imports_2,
    class: "picture"
  })], -1)), _createElementVNode("div", _hoisted_17, [_createElementVNode("span", _hoisted_18, [$data.titlelan == 'zh' ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(_ctx.$t('IsSucPage.sendemail')), 1)) : _createCommentVNode("", true), $data.titlelan == 'en' ? (_openBlock(), _createElementBlock("div", _hoisted_20, [_createTextVNode(_toDisplayString(_ctx.$t('IsSucPage.sendemailf')), 1), _cache[19] || (_cache[19] = _createElementVNode("br", null, null, -1)), _createTextVNode(_toDisplayString(_ctx.$t('IsSucPage.sendemails')), 1)])) : _createCommentVNode("", true)])])])], 2), _createElementVNode("div", {
    class: _normalizeClass($data.circlebtn),
    onClick: _cache[2] || (_cache[2] = (...args) => $options.onClickView && $options.onClickView(...args))
  }, [_createElementVNode("div", null, [_cache[22] || (_cache[22] = _createElementVNode("div", {
    class: "picture centerall"
  }, [_createElementVNode("img", {
    src: _imports_3,
    class: "picture"
  })], -1)), _createElementVNode("div", _hoisted_21, [_createElementVNode("span", _hoisted_22, [$data.titlelan == 'zh' ? (_openBlock(), _createElementBlock("div", _hoisted_23, _toDisplayString(_ctx.$t('IsSucPage.View')), 1)) : _createCommentVNode("", true), $data.titlelan == 'en' ? (_openBlock(), _createElementBlock("div", _hoisted_24, [_createTextVNode(_toDisplayString(_ctx.$t('IsSucPage.Viewf')), 1), _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1)), _createTextVNode(_toDisplayString(_ctx.$t('IsSucPage.Viewt')), 1)])) : _createCommentVNode("", true)])])])], 2)]), _createElementVNode("div", _hoisted_25, [this.btmpic ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    src: $data.btmpic,
    style: {
      "width": "100%"
    }
  }, null, 8, _hoisted_26)) : (_openBlock(), _createElementBlock("img", _hoisted_27))]), $data.titlelan == 'zh' ? (_openBlock(), _createElementBlock("div", _hoisted_28, [_createElementVNode("span", _hoisted_29, [_createTextVNode(_toDisplayString(_ctx.$t('IsSucPage.problem')), 1), _createElementVNode("a", _hoisted_30, _toDisplayString(_ctx.$t('IsSucPage.contact')), 1)])])) : _createCommentVNode("", true), $data.titlelan == 'en' ? (_openBlock(), _createElementBlock("div", _hoisted_31, [_createElementVNode("span", _hoisted_32, [_createTextVNode(_toDisplayString(_ctx.$t('IsSucPage.problem')), 1), _cache[23] || (_cache[23] = _createElementVNode("br", null, null, -1)), _createElementVNode("a", _hoisted_33, _toDisplayString(_ctx.$t('IsSucPage.contact')), 1)])])) : _createCommentVNode("", true)])], 4), _createElementVNode("div", _hoisted_34, [_withDirectives(_createElementVNode("div", {
    class: "toastshow toastpos systemfont",
    style: _normalizeStyle({
      width: $data.toastwidth
    })
  }, [_createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.$t('IsSucPage.sending')), 1), _createElementVNode("div", _hoisted_36, [_createVNode(_component_van_icon, {
    name: "cross",
    size: "14",
    onClick: _cache[3] || (_cache[3] = $event => this.toasting = false)
  })])], 4), [[_vShow, $data.toasting]]), _withDirectives(_createElementVNode("div", {
    class: "toastshow2 toastpos systemfont",
    style: _normalizeStyle({
      width: $data.toastwidth
    })
  }, [_createElementVNode("div", _hoisted_37, [_createVNode(_component_van_icon, {
    name: "success"
  })]), this.switch == 1 ? (_openBlock(), _createElementBlock("div", _hoisted_38, [_createElementVNode("div", null, _toDisplayString(_ctx.$t('IsSucPage.sendsucem')), 1), _createElementVNode("div", null, _toDisplayString($data.email), 1)])) : _createCommentVNode("", true), this.switch == 0 ? (_openBlock(), _createElementBlock("div", _hoisted_39, [_createElementVNode("div", null, _toDisplayString(_ctx.$t('IsSucPage.sendsucph')), 1), _createElementVNode("div", null, _toDisplayString($data.sjh), 1)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_40, [_createVNode(_component_van_icon, {
    name: "cross",
    size: "14",
    onClick: _cache[4] || (_cache[4] = $event => this.toasted = false)
  })])], 4), [[_vShow, $data.toasted]])]), _createVNode(_component_van_popup, {
    show: $data.showMobile,
    "onUpdate:show": _cache[8] || (_cache[8] = $event => $data.showMobile = $event),
    round: "",
    style: {
      margin: '28px',
      padding: '20px',
      width: 'auto'
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_41, _toDisplayString(_ctx.$t('IsSucPage.poptitlez')), 1), _createElementVNode("div", _hoisted_42, _toDisplayString(_ctx.$t('IsSucPage.popcont')) + _toDisplayString($data.remaintimes), 1), _createElementVNode("div", {
      style: _normalizeStyle([{
        "margin-left": "-14px"
      }, {
        width: $data.inputwidth
      }])
    }, [_createElementVNode("div", {
      class: _normalizeClass($data.sjhstyle)
    }, [_createVNode(_component_van_field, {
      id: "sjhid",
      onFocus: $options.focusedsjh,
      onBlur: $options.unfocusedsjh,
      autocomplete: "off",
      class: "uptextfont",
      modelValue: $data.sjh,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => $data.sjh = $event),
      clearable: ""
    }, null, 8, ["onFocus", "onBlur", "modelValue"]), _createElementVNode("span", {
      class: _normalizeClass([{
        'focusBlur': $data.changeIndexsjh == 1
      }, {
        'focusBlurTwo': $data.changeIndexsjh == 0
      }])
    }, _toDisplayString($data.sjhphi), 3)], 2)], 4), _withDirectives(_createElementVNode("div", _hoisted_43, [_createElementVNode("span", _hoisted_44, _toDisplayString(_ctx.$t('PopSendErr.mobile')), 1)], 512), [[_vShow, $data.errmsge]]), _withDirectives(_createElementVNode("div", _hoisted_45, [_createElementVNode("span", _hoisted_46, _toDisplayString(_ctx.$t('PopSendErr.time')), 1)], 512), [[_vShow, $data.timesg]]), _createElementVNode("button", {
      class: _normalizeClass($data.closeyes),
      style: {
        "margin-top": "40px"
      },
      onClick: _cache[6] || (_cache[6] = (...args) => $options.onClickConfirmPhone && $options.onClickConfirmPhone(...args))
    }, _toDisplayString(_ctx.$t('IsSucPage.closeyes')), 3), _createElementVNode("button", {
      class: _normalizeClass($data.closecancel),
      onClick: _cache[7] || (_cache[7] = (...args) => $options.onClickClosePhone && $options.onClickClosePhone(...args))
    }, _toDisplayString(_ctx.$t('IsSucPage.closecancel')), 3)]),
    _: 1
  }, 8, ["show"]), _createVNode(_component_van_popup, {
    show: $data.showEmail,
    "onUpdate:show": _cache[12] || (_cache[12] = $event => $data.showEmail = $event),
    round: "",
    style: {
      margin: '28px',
      padding: '20px',
      width: 'auto'
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_47, _toDisplayString(_ctx.$t('IsSucPage.poptitleo')), 1), _createElementVNode("div", _hoisted_48, _toDisplayString(_ctx.$t('IsSucPage.popcont')) + _toDisplayString($data.remaintimes), 1), _createElementVNode("div", {
      style: _normalizeStyle([{
        "margin-left": "-14px"
      }, {
        width: $data.inputwidth
      }])
    }, [_createElementVNode("div", {
      class: _normalizeClass($data.emailstyle)
    }, [_createVNode(_component_van_field, {
      id: "emailid",
      onFocus: $options.focusedemail,
      onKeyup: $options.clearBlank,
      onBlur: $options.unfocusedemail,
      autocomplete: "off",
      class: "uptextfont",
      modelValue: $data.email,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => $data.email = $event),
      clearable: ""
    }, null, 8, ["onFocus", "onKeyup", "onBlur", "modelValue"]), _createElementVNode("span", {
      class: _normalizeClass([{
        'focusBlur': $data.changeIndexemail == 1
      }, {
        'focusBlurTwo': $data.changeIndexemail == 0
      }])
    }, _toDisplayString($data.emailphi), 3)], 2)], 4), _withDirectives(_createElementVNode("div", _hoisted_49, [_createElementVNode("span", _hoisted_50, _toDisplayString(_ctx.$t('PopSendErr.email')), 1)], 512), [[_vShow, $data.errmsgf]]), _withDirectives(_createElementVNode("div", _hoisted_51, [_createElementVNode("span", _hoisted_52, _toDisplayString(_ctx.$t('PopSendErr.time')), 1)], 512), [[_vShow, $data.timesg]]), _createElementVNode("button", {
      class: _normalizeClass($data.closeyes),
      style: {
        "margin-top": "40px"
      },
      onClick: _cache[10] || (_cache[10] = (...args) => $options.onClickConfirmEmail && $options.onClickConfirmEmail(...args))
    }, _toDisplayString(_ctx.$t('IsSucPage.closeyes')), 3), _createElementVNode("button", {
      class: _normalizeClass($data.closecancel),
      onClick: _cache[11] || (_cache[11] = (...args) => $options.onClickCloseEmail && $options.onClickCloseEmail(...args))
    }, _toDisplayString(_ctx.$t('IsSucPage.closecancel')), 3)]),
    _: 1
  }, 8, ["show"]), _createVNode(_component_van_popup, {
    show: $data.showdownFail,
    "onUpdate:show": _cache[15] || (_cache[15] = $event => $data.showdownFail = $event),
    round: "",
    style: {
      margin: '28px',
      padding: '20px',
      width: 'auto'
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_53, _toDisplayString(_ctx.$t('ManPage.errtitle')), 1), _createElementVNode("div", _hoisted_54, _toDisplayString(_ctx.$t('PopMsgViewPage.load')), 1), _createElementVNode("button", {
      class: _normalizeClass($data.closeyes),
      onClick: _cache[13] || (_cache[13] = $event => this.showdownFail = false)
    }, _toDisplayString(_ctx.$t('ManPage.closeyes')), 3), _createElementVNode("button", {
      class: _normalizeClass($data.closecancel),
      onClick: _cache[14] || (_cache[14] = $event => this.showdownFail = false)
    }, _toDisplayString(_ctx.$t('ManPage.closecancel')), 3)]),
    _: 1
  }, 8, ["show"])]);
}